import React, { useState, useEffect } from 'react'
import MapGoogle from "./MapGoogle"
import FormContactnew from "./FormContactnew"
import { getStrapiContact } from '../../data/getStrapiContact'

const Contact = () => {
  const [contactData, setContactData] = useState([])
  const collectionData = 'contact-sections?populate=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiContact(collectionData)
      setContactData(data)
    }
    fetchData()
  }, [])

  const Title = ({ text }) => <h1 className='font-medium dark:text-white text-gray-600 text-2xl'>
    {text}
  </h1>

  return (
    <main id="contact" className='flex flex-col items-center align-center justify-center min-h-[100%] w-full my-4'>
      {contactData?.map(({contactTitle, contactSubtitle, contactSubtitleDown, contactAddress, contactNumber, contactEmail, contactMap, id}) => (
        <div key={id}>
          <div className='flex flex-col min-h-[100%] items-center justify-center w-full m-auto'>
            <section className='max-w-5xl min-h-[100%] container mx-auto px-4 sm:px-6 md:px-8 py-6'>
              <h1 className='pt-5 pb-2 text-center text-black dark:text-white text-2xl font-bold uppercase md:text-3xl'>{contactTitle}</h1>
              <p className='text-gray-600 dark:text-gray-400 text-lg font-normal text-center whitespace-pre-wrap'>{contactSubtitle}</p>
              <p className='text-gray-600 dark:text-gray-400 text-lg font-normal text-center whitespace-pre-wrap'>{contactSubtitleDown}</p>

              <div className='grid md:grid-cols-3 grid-cols-2 gap-8 mx-auto my-10'>
                <div className='flex flex-col justify-center gap-8 text-justify md:col-span-1 col-span-2'>
                  <div className='w-full'>
                    <Title text='Address' />
                    <address className='font-italic text-lg text-gray-600 dark:text-gray-400 whitespace-pre-wrap'>
                      {contactAddress}
                    </address>
                  </div>

                  <div className='max-w-xl'>
                    <Title text='Number ' />
                    <a href="tel:+19704173053" className='font-italic text-lg text-gray-600 whitespace-pre-wrap dark:text-gray-400'>
                      {contactNumber}
                    </a>
                  </div>

                  <div>
                    <Title text='Email' />
                    <a href="mailto:Support@tellurideislconstruction.com" className='font-italic text-lg text-gray-600 whitespace-pre-wrap dark:text-gray-400'>
                      {contactEmail}
                    </a>
                  </div>
                </div>

                <div className='col-span-2'>
                  <FormContactnew contactAddress={contactAddress} />
                </div>
              </div>
            </section>
          </div>
          <div className="px-5 w-full">
            <MapGoogle contactMap={contactMap} />
          </div>
        </div>
      ))}
    </main>
  )
}

export default Contact
