import React, { useState, useEffect } from 'react'
import { getStrapiServiceCards } from '../data/getStrapiServiceCards'

function ServicesCards() {
  const [ data, setData ] = useState([])
  const collectionData = 'service-cards?populate=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiServiceCards(collectionData)
      setData(data)
      console.log(data)
    }
    fetchData()
  },[])

  return (
    <section className='flex items-center gap-6 justify-center flex-wrap py-[3rem] w-[80%]'>
       {data?.map(({ id, cardServiceTitle, cardServiceIcon, cardServiceImage }) => (
        <div key={id} className='w-[12rem] h-[12rem] rounded' style={{ backgroundImage: `url(${cardServiceImage})`}}>
          <div className='h-full w-full  bg-orange-700/30 dark:from-neutral-200/20 hover:bg-gray-600/20 to-neutral-500/40 dark:to-slate-900 bg-cover flex items-center justify-center flex-col'>
            <img src={cardServiceIcon} alt="icon" className='w-[60%]' />
            <span className='text-white uppercase font-[16px] font-bold'>{cardServiceTitle}</span>
          </div>
        </div>
       ))}
    </section>
  )
}

export default ServicesCards
