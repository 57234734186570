import React, { useState } from 'react'
import './App.css'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import ServicesCards from './components/ServicesCards'
import Services from './components/Services'
import Gallery from './components/Gallery'
import Contact from './components/Contact'
import Footer from './components/Footer'

const App = () => {
  const [ theme, setTheme ] = useState(true)
  const [ option, setOption ] = useState("")

  const changeTheme = () => ( setTheme(!theme) )
  const updateOption = (newOption) => ( setOption(newOption) )

  switch (theme) {
    case true:
      document.documentElement.classList.add('light')
      document.documentElement.classList.remove('dark')
      break
    case false:
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
      break
    default:
      document.documentElement.classList.add('light')
      break
  }

  return (
    <div className='antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900'>
      <Navbar changeTheme={changeTheme} theme={theme} />
      <Hero />
      <div className='flex items-center flex-col justify-center bg-neutral-100/90 dark:bg-slate-800/90'>
        <ServicesCards />
        <div className='max-w-5xl'>
          <Services updateOption={updateOption} />
          <Gallery option={option} />
        </div>
        <div className='max-w-5xl'>
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
