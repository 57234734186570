import React, { useState, useEffect } from 'react'
import { getStrapiCollections } from '../data/getStrapiCollections'
import { Link } from 'react-scroll'

const Home = () => {
  const [homeData, setHomeData] = useState([])
  const collectionData = 'hero-sections?populate[hero_image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiCollections(collectionData)
      setHomeData(data)
    }
    fetchData()
  }, [])

  return (
    <>
      { homeData?.map(({ id, heroImage, heroTitle, heroContent, heroSubtitle }) => (
        <div id='home' key={id} className='w-full h-[80vh] bg-cover' style={{ backgroundImage: `url(${heroImage})` }}>
          <div className='h-full w-full bg-gradient-to-tr from-cyan-500/40 dark:from-neutral-200/20 to-neutral-500/40 dark:to-slate-900 bg-cover'>
            <div className='grid justify-items-center space-x-4 w-full h-full'>
              <div className='flex items-center justify-center w-full h-full'>
                <div className='text-center'>
                  <h1 className='font-bold lg:text-[2.8rem] text-xl text-white dark:text-neutral-200'>{`${heroTitle}`}</h1>
                  <h4 className='text-[20px] my-4 text-white dark:text-neutral-200'>{`${heroSubtitle}`}</h4>
                  <p className='font-regular text-[16px] w-[90%] md:w-[60%] lg:w-[35%] mx-auto mb-6 text-white dark:text-neutral-200'>{`${heroContent}`}</p>
                  <Link to='contact' smooth spy className="bg-orange-500 text-white hover:text-orange-500 hover:bg-gray-100 font-semibold py-3 px-6 border border-gray-400 rounded shadow">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default Home
